/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages


                //---------------------------//
                // TARTE AU CITRON - COOKIES
                //---------------------------//
                tarteaucitron.init({
                    "hashtag": "#tarteaucitron", /* Ouverture automatique du panel avec le hashtag */
                    "highPrivacy": false, /* désactiver le consentement implicite (en naviguant) ? */
                    "orientation": "bottom", /* le bandeau doit être en haut (top) ou en bas (bottom) ? */
                    "adblocker": false, /* Afficher un message si un adblocker est détecté */
                    "showAlertSmall": false, /* afficher le petit bandeau en bas à droite ? */
                    "cookieslist": true, /* Afficher la liste des cookies installés ? */
                    "removeCredit": false, /* supprimer le lien vers la source ? */
                    "handleBrowserDNTRequest": false /* Répondre au DoNotTrack du navigateur ?*/

                });

                (tarteaucitron.job = tarteaucitron.job || []).push('youtube');
                tarteaucitron.user.googlemapsKey = $googleApiKey;
                tarteaucitron.user.mapscallback = 'initMapsCookiesAllows'; // optionnal - for markers
                (tarteaucitron.job = tarteaucitron.job || []).push('googlemaps');


                tarteaucitron.user.gtagUa = 'UA-25053889-2';
                tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
                (tarteaucitron.job = tarteaucitron.job || []).push('gtag');


                //toggler burger
                $('.burger, .close-navbar').on('click', function (e) {
                    e.preventDefault();
                    $(this).toggleClass('toggled');
                    $('.navbar-collapse').fadeToggle();
                });



                //---------------------------//
                // FULL Height
                // Apply a full screen height to a div and a min height of 500px
                //---------------------------//
                function fullHeight() {
                    var windowHeight = $(window).height();
                    var navCarouselHeight = $('#thumbnailSlider').innerHeight();
                    $('.full_height .item').css('height', windowHeight - navCarouselHeight);
                }

                fullHeight();



                //---------------------------//
                // Bloc_vente_usine
                //---------------------------//

                if ( $( ".boc_vente_usine" ).length ) {
                    var blocventeusinetop = $('.boc_vente_usine').offset().top;
                    var windowhalfheight = $(window).height() / 2;
                    var blocusine_revealed = blocventeusinetop - windowhalfheight;


                    $(window).scroll(function() {
                        if ($(this).scrollTop() > blocusine_revealed) {        // If page is scrolled more than 50px
                            $('.boc_vente_usine').addClass('revealed');

                        }else {
                            $('.boc_vente_usine').removeClass('revealed');
                        }
                    });
                }
 

                

                //---------------------------//
                // OWL CAROUSEL 
                //---------------------------//


                $('#owl_slider_moreImgProduct').owlCarousel({
                    items: 1,
                    loop: true,
                    nav: true,
                    dots: false,
                    autoplay: false,
                    autoplayHoverPause: true,
                    autoHeight: false,
                    autoWidth: false,
                    lazyLoad: true,
                    lazyLoadEager: 2,
                    navText: ['<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1602 16.5L16.5801 0L0 16.5" transform="translate(1 33.5787) rotate(-90)" stroke="#858585"/></svg>', '<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1602 16.5L16.5801 0L0 16.5" transform="translate(17 0.418579) rotate(90)" stroke="#858585"/></svg>']
                });



                var numberImgGalery = $('#owl_slider_imgProduct').data('numberImgGalery');
                if (numberImgGalery >= 2) {
                    $loopDesktop = true;
                    $loopTablet = true;
                    $loopMobile = true;
                } else {
                    $loopDesktop = false;
                    $loopTablet = false;
                    $loopMobile = false;
                }

                $('#owl_slider_imgProduct').owlCarousel({
                    loop: $loopDesktop,
                    nav: false,
                    dots: false,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    autoWidth: true,
                    autoHeight: true,
                    margin: "12px",
                    lazyLoad: false,
                    lazyLoadEager: 2,
                    navText: ['<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1602 16.5L16.5801 0L0 16.5" transform="translate(1 33.5787) rotate(-90)" stroke="#858585"/></svg>', '<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1602 16.5L16.5801 0L0 16.5" transform="translate(17 0.418579) rotate(90)" stroke="#858585"/></svg>']
                });


                $('#owl_slider_references_js').owlCarousel({
                    items: 1,
                    loop: true,
                    nav: true,
                    dots: true,
                    autoplayTimeout: 5000,
                    autoplay: true,
                    lazyLoad: true,
                    lazyLoadEager: 2,
                    autoplayHoverPause: true,
                    navText: ['<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1602 16.5L16.5801 0L0 16.5" transform="translate(1 33.5787) rotate(-90)" stroke="#858585"/></svg>', '<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1602 16.5L16.5801 0L0 16.5" transform="translate(17 0.418579) rotate(90)" stroke="#858585"/></svg>']
                });



                $('#owl_actualite_js').owlCarousel({
                    items: 1,
                    loop: true,
                    nav: true,
                    dots: false,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    lazyLoad: true,
                    autoplayHoverPause: true,
                    lazyLoadEager: 2,
                    navText: ["<svg width='20' height='35' version='1.1' xmlns='http://www.w3.org/2000/svg'><g fill='none'><path d='M18.3 1.108L1.8 17.688l16.5 16.58' stroke='#fff' stroke-width='2'/></g></svg>", "<svg width='20' height='35' version='1.1' xmlns='http://www.w3.org/2000/svg'><g fill='none'><path d='M1.5 34.268L18 17.688 1.5 1.108' stroke='#fff' stroke-width='2'/></g></svg>"]
                });


                $('#accordion_shop').on('shown.bs.collapse', function () {
                    $('.owl_slider_shop').owlCarousel({
                        items: 1,
                        loop: true,
                        nav: true,
                        dots: false,
                        autoplayTimeout: 5000,
                        autoplay: true,
                        lazyLoad: true,
                        lazyLoadEager: 2,
                        autoHeight: false,
                        autoWidth: false,
                        autoplayHoverPause: true,
                        navText: ['<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1602 16.5L16.5801 0L0 16.5" transform="translate(1 33.5787) rotate(-90)" stroke="#858585"/></svg>', '<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.1602 16.5L16.5801 0L0 16.5" transform="translate(17 0.418579) rotate(90)" stroke="#858585"/></svg>']
                    });
                });
                
                
                

                $('.matchHeight_js').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });


                //---------------------------//
                //SCROLL TO 
                //---------------------------//
                //scroll page anchor in other page

                var offsetSize = $(".header .fixed-top").innerHeight();
                $('a.scrollTo').on('click',function(e) { // Au clic sur un élément //a[href*=\\#]

                    var target = this.hash;
                    var $target = $(target);

                    var targetname = target.slice(1, target.length);


                    if(document.getElementById(targetname) != null) { //if element is present in page
                        e.preventDefault();

                        $('html, body').stop().animate({
                            'scrollTop': $target.offset().top-offsetSize //or the height of your fixed navigation 

                        }, 900);

                    }else{
                        //scroll page anchor in other page
                        $("html, body").animate({scrollTop:$(window.location.hash).offset().top-offsetSize }, 750);
                    }
                });



                //---------------------------//
                // slider more image
                //---------------------------//

                $('#show_more_img_js').on('click', function (e) {
                    e.preventDefault();

                    $('html, body').animate({
                        scrollTop: $("#product-moreImg").offset().top
                    }, 500, function () {
                        // Animation complete.
                        $('#product-moreImg').addClass('show');
                    });
                });

                $('#product-moreImg .icon-close').on('click', function (e) {
                    e.preventDefault();
                    $('#product-moreImg').removeClass('show');
                });



                //---------------------------//
                // Remove menu from espace pro if connected
                //---------------------------//
                if( $('body').hasClass('isconnected')){
                    $('.d-none-connected').css('display','none');
                }

                //---------------------------//
                // SHOW SEARCH BAR
                //---------------------------//

                $('#search-link').on('click', function (e) {
                    e.preventDefault();
                    $('.search-form').fadeToggle();
                    $('.icon-search-close').fadeToggle();
                });



                //---------------------------//
                // EFFET CONTACT FORM
                //---------------------------//

                $("input:not(:checkbox):not(:radio), select, textarea").focus(function () {
                    $(this).closest('.form-group').addClass('input-focus');
                });

                $("input:not(:checkbox):not(:radio), select, textarea")
                    .focusout(function () {
                    $(this).closest('.form-group').removeClass('input-focus');
                })
                    .focusin(function () {
                    $(this).closest('.form-group').addClass('input-focus');
                });

                $('input:not(:checkbox):not(:radio), select, textarea').blur(function () {
                    if ($(this).val().length !== 0) {
                        $(this).closest('.form-group').addClass('input-focus');
                    }
                });



                //---------------------------//
                // TAB
                //---------------------------//

                $('#myTab .nav-link').on('click', function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                });

                $('#myTab li:first-child .nav-link').tab('show'); // Select first tab




                //---------------------------//
                // LIVE SEARCH
                //---------------------------//

                (function() {
                    var searchTerm, panelContainerId;
                    $('.live-search-box').on('change keyup', function() {
                        searchTerm = $(this).val();
                        $('.card').each(function() {
                            panelContainerId = '#' + $(this).attr('id');
                            
                            // Makes search to be case insesitive 
                            $.extend($.expr[':'], {
                                'contains': function(elem, i, match, array) {
                                    return (elem.textContent || elem.innerText || '').toLowerCase()
                                        .indexOf((match[3] || "").toLowerCase()) >= 0;
                                }
                            });

                            // END Makes search to be case insesitive

                            // Show and Hide Triggers
                            $(panelContainerId + ':not(:contains(' + searchTerm + '))').hide(); //Hide the rows that done contain the search query.
                            $(panelContainerId + ':contains(' + searchTerm + ')').show(); //Show the rows that do!

                            
                            $('.card-body').unhighlight();
                            $('.card-body').highlight(searchTerm, {}, function(el) {
                                
                            });
                            
                        });
                    });
                }());

                // End Show and Hide Triggers

                // END This section makes the search work.




                //---------------------------//
                // ADD EMAIL ADRESSE ON CLUBISTE PAGE FORM
                //---------------------------//

                $emailClubiste = $('#email_clubiste').text();
                $('.recipient_email').val($emailClubiste);


                //---------------------------//
                // LIGHTBOX
                //---------------------------//

                $('.lightBox_coloris_js .lightbox').simpleLightbox();
                $('.lightBox_productImg_js .lightbox').simpleLightbox();
                $('.lightBox_productMoreImg_js .lightbox').simpleLightbox();
                $('.lightBox_accessory_js .lightbox-accessory').simpleLightbox();
                $('.lightBox_accessory_js .lightbox-coloris').simpleLightbox();
                $('.lightBox_options_js .lightbox').simpleLightbox();



                //---------------------------//
                // sticky header
                //---------------------------//

                function heightHeader() {
                    var navHeight =  $('.navbar').height();
                    var navProHeight = $('#espace_pro_nav').height();
                    $('.header').css('height', navHeight + navProHeight);   
                }
                heightHeader();  



                //---------------------------//
                // CONTENT MIN HEIGHT
                //---------------------------//

                function windowMinHeight() {
                    var windowHeight =  $(window).outerHeight();
                    var headerHeight =  $('.header').outerHeight();
                    var footerHeight =  $('footer.content-info').outerHeight();
                    $('main').css('min-height', windowHeight - ( headerHeight + footerHeight ) );   
                }
                windowMinHeight();  




                //---------------------------//
                // On resize
                //---------------------------//

                $(window).resize(function () {
                    fullHeight();
                    heightHeader();
                    windowMinHeight();  
                });



                //---------------------------//
                // ===== Scroll to Top ==== 
                //---------------------------//

                $(window).scroll(function() {
                    if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
                        $('#return-to-top').fadeIn(200);    // Fade in the arrow
                    } else {
                        $('#return-to-top').fadeOut(200);   // Else fade out the arrow
                    }
                });
                $('#return-to-top').click(function() {      // When arrow is clicked
                    $('body,html').animate({
                        scrollTop : 0                       // Scroll to top of body
                    }, 500);
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

            }
        },
        // Home page
        'page_template_front_page': {
            init: function () {
                // JavaScript to be fired on the home page

                crossmodal = $('.modalcross');
                $numberofactus = $(".actus--wraper .itemapp").length;
                waiting = $numberofactus * 200 + 200;

                crossmodal.click(function(e){
					e.preventDefault();
                    $('.actus--wraper .itemapp').addClass('removeditem');
                    setTimeout(function(){
                          $('.modalbg').hide('fast');
                        }, waiting);
                });


                $numberBook = $('#owl_slider_books_js').data('booknumber');
                if ($numberBook > 3) {
                    $loopDesktop = true;
                    $loopTablet = true;
                    $loopMobile = true;
                } else if ($numberBook == 3) {
                    $loopDesktop = false;
                    $loopTablet = true;
                    $loopMobile = true;
                } else if ($numberBook == 2) {
                    $loopDesktop = false;
                    $loopTablet = false;
                    $loopMobile = true;
                } else {
                    $loopDesktop = false;
                    $loopTablet = false;
                    $loopMobile = false;
                }


                $('#owl_slider_books_js').owlCarousel({
                    nav: true,
                    autoplay: false,
                    lazyLoad: true,
                    lazyLoadEager: 2,
                    responsive: {
                        // breakpoint from 0 up
                        0: {
                            items: 1,
                            loop: $loopMobile,
                        },

                        // breakpoint from 768 up
                        560: {
                            items: 2,
                            loop: $loopTablet,
                        },

                        991: {
                            items: 3,
                            loop: $loopDesktop,
                        }
                    },
                    navText: ["<svg width='20' height='35' version='1.1' xmlns='http://www.w3.org/2000/svg'><g fill='none'><path d='M18.3 1.108L1.8 17.688l16.5 16.58' stroke='#96969A' stroke-width='2'/></g></svg>", "<svg width='20' height='35' version='1.1' xmlns='http://www.w3.org/2000/svg'><g fill='none'><path d='M1.5 34.268L18 17.688 1.5 1.108' stroke='#96969A' stroke-width='2'/></g></svg>"],

                });


                $('#owl_slider_home_js').owlCarousel({
                    items: 1,
                    animateOut: 'fadeOut',
                    loop: true,
                    nav: false,
                    dots: false,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    lazyLoad: true,
                    lazyLoadEager: 2
                });



            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);	

})(jQuery); // Fully reference jQuery after this point.


//---------------------------//
// MAP
//---------------------------//


function initMapsCookiesAllows() { 
    (function ($) {		
        /*
		 *  document ready
		 *
		 *  This function will render each map when the document is ready (page has loaded)
		 *
		 *  @type	function
		 *  @date	8/11/2013
		 *  @since	5.0.0
		 *
		 *  @param	n/a
		 *  @return	n/a
		 */
        // global var
        var map = null;
        // Variable qui contiendra l'instance de notre bibliothèque
        var markerClusterer, autocomplete, places;
        // Variable qui contiendra le tableau de nos marqueurs clubiste
        var markers = [];
        var markersR = []; // Marqueur revendeurs

        $(document).ready(function () {
            $('.acf-map').each(function () {

                // create map
                map = new_map($(this));

            });
            $('[data-toggle="tooltip"]').tooltip();
        });


        /*
		 *  new_map
		 *
		 *  This function will render a Google Map onto the selected jQuery element
		 *
		 *  @type	function
		 *  @date	8/11/2013
		 *  @since	4.3.0
		 *
		 *  @param	$el (jQuery element)
		 *  @return	n/a
		 */

        function new_map($el) {
            // On affiche les div masquées
            $('.hidden').show();

            // var
            var $markers = $el.find('.marker');
            var $markersResellers = $el.find('.marker_reseller');
            var center =  new google.maps.LatLng(46.52863469527167,2.43896484375);

            // vars
            var args = {
                zoom: 7,
                minZoom: 4,
                center: center,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scaleControl: true,
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "weight": "2.00"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#9c9c9c"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#7b7b7b"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#46bcec"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c8d7d4"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#070707"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    }
                ]

            };

            // create map	        	
            var map = new google.maps.Map($el[0], args);
                

            // Initialisation autocomplete
            var options = {
                componentRestrictions: {country: 'fr'},
                types: ["geocode"]
             };
            var input = document.getElementById('autocomplete');
            autocomplete = new google.maps.places.Autocomplete(input, options);
            places = new google.maps.places.PlacesService(map);
            
                
            
            // add a markers reference
            map.markers = [];

            // Création de l'instance du système de clusters
            //-- clubiste
            var mcOptions = {
                maxZoom: 5,
                cssClass: 'custom-pin-clubiste'
            };
            markerClusterer = new MarkerClusterer(map, markers, mcOptions);

            //-- revendeur
            var mcROptions = {
                maxZoom: 5, // maxZoom set when clustering will stop
                cssClass: 'custom-pin-reseller'
            };
              RmarkerClusterer = new MarkerClusterer(map, markersR, mcROptions);

             // add markers
            $markers.each(function () {
                add_marker($(this), map);
                
            });

            $markersResellers.each(function () {
                add_marker_reseller($(this), map);
            }); 


            // center map
            center_map(map);

            // return
            return map;

        }
        
        /*
		 *  add_marker
		 *
		 *  This function will add a marker to the selected Google Map
		 *
		 *  @type	function
		 *  @date	8/11/2013
		 *  @since	4.3.0
		 *
		 *  @param	$marker (jQuery element)
		 *  @param	map (Google Map object)
		 *  @return	n/a
		 */
        var currentWindow = null; // contiendra l'information de la bulle actuellement ouverte
        function add_marker($marker, map) {
            
            // var
            var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
          

            // create marker
            var icon = {
                url: image_path + "markerC@2x.png", // url
                scaledSize: new google.maps.Size(50, 62) // scaled size
            };
            var marker = new google.maps.Marker({
                position: latlng,
                icon: icon,
                map: map,
                type: 'clubiste'
            });
            // add to array
            map.markers.push(marker);

            markers.push(latlng);
            // Ajout du marqueur au système de clusters
            markerClusterer.addMarker(marker);

            // if marker contains HTML, add it to an infoWindow
            if ($marker.html()) {
                // create info window
                var infowindow = new google.maps.InfoWindow({
                    content: $marker.html(),
                });
                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function () {
                    // Si une bulle est déjà ouverte
                    if (currentWindow != null) {
                        // On la ferme
                        currentWindow.close();
                        // On vide la variable
                        currentWindow = null;
                    }
                    // On ouvre la bulle correspondant à notre marqueur
                    infowindow.open(map, marker);
                    // On enregistre cette bulle dans la variable currentPopup
                    currentWindow = infowindow;
                });

                // START INFOWINDOW CUSTOMIZE.
                // The google.maps.event.addListener() event expects
                // the creation of the infowindow HTML structure 'domready'
                // and before the opening of the infowindow, defined styles are applied.
                // *
                google.maps.event.addListener(infowindow, 'domready', function() {

                    // Reference to the DIV that wraps the bottom of infowindow
                    var iwOuter = $('.gm-style-iw');

                    /* Since this div is in a position prior to .gm-div style-iw.
                    * We use jQuery and create a iwBackground variable,
                    * and took advantage of the existing reference .gm-style-iw for the previous div with .prev().
                    */
                    //var iwBackground = iwOuter.prev();
					var iwBackground = iwOuter;
                    
                    iwOuter.children().css({'width':'100%'}); 

                    // Removes background shadow DIV
                    iwBackground.children(':nth-child(2)').css({'display' : 'none'});

                    // Removes white background DIV
                    iwBackground.children(':nth-child(4)').css({'display' : 'none'});

                    // Moves the shadow of the arrow 76px to the left margin.
                    iwBackground.children(':nth-child(1)').attr('style', function(i,s){ return s + 'left: 76px !important;'});

                    // Moves the arrow 76px to the left margin.
                    iwBackground.children(':nth-child(3)').attr('style', function(i,s){ return s + 'left: 76px !important;'});

                    // Changes the desired tail shadow color.
                    iwBackground.children(':nth-child(3)').find('div').children().css({'box-shadow': 'rgba(72, 181, 233, 0.6) 0px 1px 6px', 'z-index' : '1'});

                    // Reference to the div that groups the close button elements.
                    //var iwCloseBtn = iwOuter.next();
					var iwCloseBtn = iwBackground.find('.cross-close');
					iwCloseBtn.click(function(){
						if (currentWindow != null) {
							// On la ferme
							currentWindow.close();
							// On vide la variable
							currentWindow = null;
						}
					});

                    // Apply the desired effect to the close button
                    //iwCloseBtn.css({opacity: '0', right: '50px', top: '35px'});

                });

                google.maps.event.addListener(infowindow, "closeclick", function() {
                    // On vide la variable
                    currentWindow = null;

                });
            }

        }

        function add_marker_reseller($marker, map) {

            // var
            var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

            // create marker
            var icon = {
                url: image_path + "markerR@2x.png", // url
                scaledSize: new google.maps.Size(50, 62) // scaled size
            };
            var marker = new google.maps.Marker({
                position: latlng,
                icon : icon,
                map: map,
                type: 'revendeur'
            });

            // add to array
            map.markers.push(marker);

            markersR.push(latlng);
            // Ajout du marqueur au système de clusters
            RmarkerClusterer.addMarker(marker);

            // if marker contains HTML, add it to an infoWindow
            if ($marker.html()) {
                // create info window
                var infowindow = new google.maps.InfoWindow({
                    content: $marker.html(),
                });

                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function () {
                
                    // Si une bulle est déjà ouverte
                    if (currentWindow != null) {
                        // On la ferme
                        currentWindow.close();
                        // On vide la variable
                        currentWindow = null;
                    }
                    // On ouvre la bulle correspondant à notre marqueur
                    infowindow.open(map, marker);
                    // On enregistre cette bulle dans la variable currentPopup
                    currentWindow = infowindow;

                });

                // START INFOWINDOW CUSTOMIZE.
                // The google.maps.event.addListener() event expects
                // the creation of the infowindow HTML structure 'domready'
                // and before the opening of the infowindow, defined styles are applied.
                // *
                google.maps.event.addListener(infowindow, 'domready', function() {

                    // Reference to the DIV that wraps the bottom of infowindow
                    var iwOuter = $('.gm-style-iw');

                    /* Since this div is in a position prior to .gm-div style-iw.
                    * We use jQuery and create a iwBackground variable,
                    * and took advantage of the existing reference .gm-style-iw for the previous div with .prev().
                    */
                    //var iwBackground = iwOuter.prev();
					var iwBackground = iwOuter;
                    
                    iwOuter.children().css({'width':'100%'})

                    // Removes background shadow DIV
                    iwBackground.children(':nth-child(2)').css({'display' : 'none'});

                    // Removes white background DIV
                    iwBackground.children(':nth-child(4)').css({'display' : 'none'});

                    // Moves the shadow of the arrow 76px to the left margin.
                    iwBackground.children(':nth-child(1)').attr('style', function(i,s){ return s + 'left: 76px !important;'});

                    // Moves the arrow 76px to the left margin.
                    iwBackground.children(':nth-child(3)').attr('style', function(i,s){ return s + 'left: 76px !important;'});

                    // Changes the desired tail shadow color.
                    iwBackground.children(':nth-child(3)').find('div').children().css({'box-shadow': 'rgba(72, 181, 233, 0.6) 0px 1px 6px', 'z-index' : '1'});

                    // Reference to the div that groups the close button elements.
                    var iwCloseBtn = iwOuter.next();

                    // Reference to the div that groups the close button elements.
                    //var iwCloseBtn = iwOuter.next();
					var iwCloseBtn = iwBackground.find('.cross-close');
					iwCloseBtn.click(function(){
						if (currentWindow != null) {
							// On la ferme
							currentWindow.close();
							// On vide la variable
							currentWindow = null;
						}
					});

                });

                google.maps.event.addListener(marker, "closeclick", function() {
                    // On vide la variable
                    currentWindow = null;

                });
            }

        }

        
        /*
		 *  center_map
		 *
		 *  This function will center the map, showing all markers attached to this map
		 *
		 *  @type	function
		 *  @date	8/11/2013
		 *  @since	4.3.0
		 *
		 *  @param	map (Google Map object)
		 *  @return	n/a
		 */

        function center_map(map) {

            // vars
            var bounds = new google.maps.LatLngBounds();

            // loop through all markers and create bounds
            $.each(map.markers, function (i, marker) {

                var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

                bounds.extend(latlng);

            });

            // only 1 marker?
            if (map.markers.length == 1) {
                // set center of map
                map.setCenter(bounds.getCenter());
                map.setZoom(15);
            } else {
                // fit to bounds
                map.fitBounds(bounds);
            }

        }


        //---------------------------//
        // SEARCH MAPS
        //---------------------------//

        if(autocomplete){

            var marker_search = null; // contient le marqueur de recherche
            var dist = null; // contient le rayon du cercle
            var circle = null; // contient le cercle
            
            $('#button_search').on('click', search_location );
            $("input").focusin(function () {
                $(document).keypress(function (e) {
                    if (e.which == 13) {
                        search_location();
                    }
                });
            });  

            autocomplete.bindTo('bounds', map);
            function search_location() {
                if($(".pac-container .pac-item:first").text() == ''){
                    var input_search = $('.input_search').val();
                }else
                    var input_search = $(".pac-container .pac-item:first").text();
        
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({"address":input_search }, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        var lat = results[0].geometry.location.lat(),
                            lng = results[0].geometry.location.lng(),
                            placeName = results[0].formatted_address,
                            latlng = new google.maps.LatLng(lat, lng);
                        moveMarker(placeName, latlng);
                        $("input").val(input_search);
                    }
                });

            }
        }

        function moveMarker(placeName, latlng){
            var icon = {
                url: image_path + "markerS@2x.png", // url
                scaledSize: new google.maps.Size(35, 49) // scaled size
            };

            dist = Number(($('#select_search').val() * 1000));
            
            var circleOption = {
                strokeColor: '#1670c3',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#1670c3',
                fillOpacity: 0.3,
                map: map,
                center: latlng,
                radius: dist, 
            };
            if (marker_search == null) {
                marker_search = new google.maps.Marker({
                position: latlng,
                icon : icon,
                map: map
                });

                circle = new google.maps.Circle(circleOption);
            }

            
            // on centre le cercle sur le marqueur de recherche
            circle.bindTo('center', marker_search, 'position');

            marker_search.setIcon(icon);
            marker_search.setPosition(latlng);
            map.setCenter(latlng);
            circle.setRadius(dist);

            bounds = circle.getBounds(); // on délimite le bord du cercle
            
            if ($('#select_search').val() != null) {
                countMarker();
            }
            
        }
        function countMarker(){
            var $inside_marker = [];
            $inside_marker.length = 0;
            
            if(circle){
                map.fitBounds(circle.getBounds());
           
                for(var i= 0; i < map.markers.length; i++)
                {   
                    if(dist > google.maps.geometry.spherical.computeDistanceBetween(map.markers[i].position, marker_search.getPosition())){
                        if(map.markers[i].visible === true){
                            $inside_marker.push(map.markers[i])
                        }
                    }
                }
            
                $('#result').show();
                $('#result span.number').html($inside_marker.length);
            }

        }
        
        //---------------------------//
        // FILTER MAPS
        //---------------------------//

        $('.filter_marker input[type="checkbox"]').on('change', filter_marker)
        function filter_marker(){

            if( $('#check_clubiste').is(':checked')){  
                map.markers.forEach(function(marker){
                    if(marker.type == "revendeur"){
                        marker.setVisible(false)
                        RmarkerClusterer.removeMarker(marker)
                    }
                });
            }
            if($('#check_reseller').is(':checked')){
                map.markers.forEach(function(marker){
                    if(marker.type == "clubiste"){
                        marker.setVisible(false)
                        markerClusterer.removeMarker(marker)
                    }
                });
            }
            if( $('#check_clubiste').is(':checked') && $('#check_reseller').is(':checked') || !$('#check_clubiste').is(':checked') && !$('#check_reseller').is(':checked') ){
                map.markers.forEach(function(marker){
                    if(marker.type == "clubiste"){
                        if (!markerClusterer.markers_.includes(marker)) {
                            marker.setVisible(true)
                            markerClusterer.addMarker(marker)
                        }
                    }     
                    if(marker.type == "revendeur"){
                        if (!RmarkerClusterer.markers_.includes(marker)) {
                            marker.setVisible(true)
                            RmarkerClusterer.addMarker(marker)
                        }
                    }          
                });
                
            }
            
            countMarker();
        }

    })(jQuery);
}